<template>
  <div ref="dropzone" class="dropzone" />
</template>

<script>
import 'dropzone/dist/dropzone.css'

import Dropzone from 'dropzone'
import * as Cookies from 'js-cookie'

Dropzone.autoDiscover = false

export default {
  name: 'Dropzone',
  props: {
    endpoint: { type: String, required: true },
    paramName: { type: String, default: 'file' },
    multiple: { type: Boolean, default: false },
    maxFilesize: { type: Number, default: 3 },
    whitelist: { type: Array, default: () => ['image/png', 'image/jpeg'] },
    withCredentials: { type: Boolean, default: true },
  },
  data() {
    return { dropzone: null }
  },
  computed: {
    dropzoneEvents() {
      return [
        'addedfile',
        'removedfile',
        'thumbnail',
        'error',
        'processing',
        'uploadprogress',
        'sending',
        'success',
        'complete',
        'canceled',
        'maxfilesreached',
        'maxfilesexceeded',
        'addedfiles',
        'processingmultiple',
        'sendingmultiple',
        'successmultiple',
        'errormultiple',
        'completemultiple',
        'canceledmultiple',
        'totaluploadprogress',
        'reset',
        'queuecomplete',
      ]
    },
  },
  mounted() {
    this.setupDropzone()
  },
  methods: {
    setupDropzone() {
      const whitelist = this.whitelist
      this.dropzone = new Dropzone(this.$refs.dropzone, {
        url: () => {
          const base = process.env.VUE_APP_API_URL
            ? process.env.VUE_APP_API_URL
            : `https://${window.location.hostname}/api`
          return `${base.trimRight('/')}/${this.endpoint}`
        },
        accept: (file, done) => {
          if (whitelist.includes(file.type)) {
            done()
          } else {
            this.$emit('unsupported', file)
          }
        },
        headers: { 'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') },
        paramName: this.paramName,
        maxFilesize: this.maxFilesize,
        uploadMultiple: this.multiple,
        withCredentials: this.withCredentials,
        addRemoveLinks: true,
       })

      // custom event propagation
      this.dropzoneEvents.forEach(event => this.dropzone.on(event, () => this.$emit(event)))
    },
  },
}
</script>
