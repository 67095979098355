<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData">
      <validation-provider name="Title" v-slot="context" rules="required">
        <b-form-group label="Title">
          <b-form-input
            v-model="title"
            :state="getValidationState(context)"
            placeholder="Gallery Name"
            v-bind="context.ariaInput"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="Description" v-slot="context" rules="required">
        <b-form-group label="Description">
          <b-form-textarea
            v-model="description"
            :state="getValidationState(context)"
            placeholder="Description"
            v-bind="context.ariaInput"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <hr />

      <b-row align-h="end">
        <b-col cols="auto">
          <b-button variant="link" class="mr-2" @click="$emit('close')">
            Close
          </b-button>

          <b-button variant="primary" :disabled="invalid" type="submit">
            {{ gallery ? 'Update' : 'Create' }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import { suppliers } from '@/api'
import { getValidationState } from '../page-helpers'

export default {
  name: 'SupplierGalleryForm',
  props: { supplier: Object, gallery: Object },
  data() {
    return { title: '', description: '' }
  },
  beforeMount() {
    if (!this.gallery) return

    this.title = this.gallery.title
    this.description = this.gallery.description
  },
  methods: {
    getValidationState,
    async submitFormData() {
      const data = { title: this.title, description: this.description }

      if (this.gallery) {
        await suppliers.gallery(this.$route.params.id).update(this.gallery.id, data)
      } else {
        await suppliers.gallery(this.$route.params.id).create({ ...data, supplier_id: this.$route.params.id })
      }

      this.$emit('success')
    },
  },
}
</script>
