<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Supplier: <span class="font-weight-normal">{{ supplier.booking_name }}</span></h1>
      </b-col>

      <b-col cols="auto">
        <b-dropdown variant="default" text="Create" right :disabled="$cannot('supplier.manage')">
          <b-dropdown-item v-b-modal.newImage>Image(s)</b-dropdown-item>
          <b-dropdown-item v-b-modal.newGallery>Gallery</b-dropdown-item>
          <b-dropdown-item v-b-modal.newContent>Content</b-dropdown-item>
        </b-dropdown>

        <b-modal id="newContent" title="New Supplier Content" hide-footer v-slot="{ close }">
          <supplier-gallery-content-form @success="handleNewContent(close)" @close="close" />
        </b-modal>

        <b-modal id="newImage" title="Upload Image" hide-footer v-slot="{ close }" @close="imageError = false">
          <dropzone
            :endpoint="supplierUploadEndpoint"
            param-name="image"
            @sending="dropzoneBusy = true"
            @complete="dropzoneBusy = false"
            @unsupported="showImageError"
          />
          <hr />
          <p :class="['modal-new-image__text', { 'modal-new-image__text_error': imageError }]">
            Bildestørrelsen må være mindre 3Mb og bildeformatet må være png eller jpeg
          </p>
          <hr />
          <b-row align-h="end">
            <b-col cols="auto">
              <b-button variant="primary" :disabled="dropzoneBusy" @click="handleNewImage(close)">Done</b-button>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal id="newGallery" title="Create New Gallery" hide-footer v-slot="{ close }">
          <supplier-gallery-form
            :default-gallery="defaultGallery"
            :supplier="supplier"
            @success="handleNewGallery(close)"
            @close="close"
          />
        </b-modal>
      </b-col>
    </b-row>

    <!-- page content -->
    <b-card no-body>
      <b-tabs card justified>
        <b-tab title="Images" :active="$route.name === 'supplier.gallery.images'" @click="tabGoTo(links.images)">
          <b-table :fields="imagesFields" :items="images" show-empty stacked="sm" bordered hover>
            <template v-slot:cell(image_small)="row">
              <b-row>
                <b-col>
                  <b-img :src="row.item.image_small" :alt="row.item.description" />
                </b-col>
                <b-col cols="auto">
                  <b-button variant="danger" class="ml-2" @click="deleteImage(row.item)">
                    <i class="fa fa-solid fa-trash" />
                  </b-button>
                </b-col>
              </b-row>
            </template>

            <template v-slot:cell(description)="{ item }">
              <b-row>
                <b-col>{{ item.description }}</b-col>
                <b-col cols="auto">
                  <b-button variant="default" @click="openImageDescriptionModal(item)">
                    <i class="fa fa-edit" />
                  </b-button>
                </b-col>

                <b-modal
                  title="Edit Description"
                  :id="`image-description-modal-${item.id}`"
                  hide-footer
                  v-slot="{ close }"
                >
                  <validation-observer v-slot="{ invalid }">
                    <form @submit.prevent="updateImageDescription(item)">
                      <validation-provider name="Description" v-slot="context" rules="required">
                        <b-form-group label="Description">
                          <b-input
                            v-model="imageDescription"
                            :state="getValidationState(context)"
                            v-bind="context.ariaInput"
                          />

                          <b-form-invalid-feedback v-bind="context.ariaMsg">
                            {{ context.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <hr />

                      <b-row align-h="end">
                        <b-col cols="auto">
                          <b-button variant="link" class="mr-2" @click="close"> Cancel </b-button>

                          <b-button variant="primary" :disabled="invalid" type="submit"> Update </b-button>
                        </b-col>
                      </b-row>
                    </form>
                  </validation-observer>
                </b-modal>
              </b-row>
            </template>
          </b-table>
        </b-tab>

        <b-tab title="Gallery" :active="$route.name === 'supplier.gallery'" @click="tabGoTo(links.gallery)">
          <b-table :fields="galleryFields" :items="galleries" show-empty stacked="sm" bordered hover>
            <template v-slot:cell(images)="{ item }">
              <b-row>
                <b-col>
                  <supplier-gallery-items :gallery="item" />
                </b-col>
                <b-col cols="auto">
                  <b-button variant="default" v-b-modal="`selectImage-${item.id}`"> Select Images </b-button>
                </b-col>
              </b-row>

              <b-modal :id="`selectImage-${item.id}`" :title="`Pick Images: ${item.title}`" size="xl" hide-footer>
                <supplier-gallery-image-picker :images="images" :gallery="item" @close="loadContent" />
              </b-modal>
            </template>

            <template v-slot:cell(description)="{ item }">
              <b-row>
                <b-col> {{ item.description }} </b-col>
                <b-col cols="auto">
                  <b-button variant="default" v-b-modal="`editGallery-${item.id}`">
                    <i class="fa fa-edit" />
                  </b-button>
                </b-col>
              </b-row>

              <b-modal
                :id="`editGallery-${item.id}`"
                :title="`Edit Gallery: ${item.title}`"
                hide-footer
                v-slot="{ close }"
              >
                <supplier-gallery-form :gallery="item" @success="handleEditGallery(close)" @close="close" />
              </b-modal>
            </template>
          </b-table>
        </b-tab>

        <b-tab title="Content" :active="$route.name === 'supplier.gallery.content'" @click="tabGoTo(links.content)">
          <b-table :fields="contentFields" :items="contentListing" show-empty stacked="sm" bordered hover>
            <template v-slot:cell(content)="row">
              <div v-html="row.item.content"></div>
            </template>
            <template v-slot:cell(options)="row">
              <b-button-group>
                <b-button variant="default" v-b-modal="`editContent-${row.item.id}`"> Edit </b-button>
                <b-button variant="default" v-b-modal="`deleteContent-${row.item.id}`"> Delete </b-button>

                <b-modal title="Delete Content" :id="`deleteContent-${row.item.id}`" @ok="deleteContent(row.item)">
                  Are you sure you want to delete
                  <strong>{{ row.item.title }}?</strong>
                </b-modal>
              </b-button-group>

              <b-modal :id="`editContent-${row.item.id}`" title="New Supplier Content" hide-footer v-slot="{ close }">
                <supplier-gallery-content-form :content="row.item" @success="handleEditContent(close)" @close="close" />
              </b-modal>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { suppliers } from '@/api'
import SupplierGalleryForm from './SupplierGalleryForm.vue'
import SupplierGalleryImagePicker from './SupplierGalleryImagePicker.vue'
import SupplierGalleryContentForm from './SupplierGalleryContentForm.vue'
import Dropzone from '../../components/Dropzone.vue'
import { getValidationState } from '../../pages/page-helpers'
import SupplierGalleryItems from './SupplierGalleryItems.vue'

export default {
  name: 'SupplierGallery',
  inject: ['$cannot'],
  components: {
    Dropzone,
    SupplierGalleryForm,
    SupplierGalleryImagePicker,
    SupplierGalleryContentForm,
    SupplierGalleryItems,
  },
  data() {
    return {
      supplier: {},
      images: [],
      galleries: [],
      defaultGallery: {},
      contentListing: [],
      dropzoneBusy: true,
      imageDescription: '',
      imageError: false,
    }
  },
  computed: {
    imagesFields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'image_small', label: 'URL' },
        { key: 'description' },
        { key: 'options', label: '' },
      ]
    },
    contentFields() {
      return [{ key: 'id', label: 'ID' }, { key: 'title' }, { key: 'content' }, { key: 'options', label: '' }]
    },
    galleryFields() {
      return [{ key: 'id', label: 'ID' }, { key: 'title' }, { key: 'images', label: 'Images' }, { key: 'description' }]
    },
    links() {
      return {
        images: {
          name: 'supplier.gallery.images',
          params: { id: this.$route.params.id },
        },
        gallery: {
          name: 'supplier.gallery',
          params: { id: this.$route.params.id },
        },
        content: {
          name: 'supplier.gallery.content',
          params: { id: this.$route.params.id },
        },
      }
    },
    supplierUploadEndpoint() {
      return `supplier/${this.$route.params.id}/images`
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.loadContent(to))
  },
  beforeRouteUpdate(to) {
    this.loadContent(to)
  },
  methods: {
    showImageError() {
      this.imageError = true
    },
    getValidationState,
    tabGoTo(route) {
      if (route.name !== this.$route.name) {
        this.$router.push(route)
      }
    },
    async loadContent(overrideRoute) {
      const route = overrideRoute ?? this.$route

      if (!this.supplier.length) {
        const response = await suppliers.find(this.$route.params.id)
        this.supplier = response.data
      }

      if (route.name === 'supplier.gallery.images') {
        return this.loadImageTab()
      }

      if (route.name === 'supplier.gallery.content') {
        return this.loadContentTab()
      }

      this.loadImageTab()
      this.loadGalleryTab()
    },
    async loadImageTab() {
      const images = await suppliers.images(this.$route.params.id).all()
      this.images = images.data.images
    },
    async loadContentTab() {
      const response = await suppliers.customContent.go(this.$route.params.id)
      this.contentListing = response.data()
    },
    async loadGalleryTab() {
      const response = await suppliers.gallery(this.$route.params.id).all()
      this.galleries = response.data
    },
    async deleteContent(content) {
      await suppliers.customContent.delete(content.id)
      this.loadContentTab()
    },

    // TODO: should refactor after backend part
    async deleteImage(item) {
      await suppliers.images(this.$route.params.id).delete(item.id)
      this.loadContent(this.$route)
    },
    openImageDescriptionModal(item) {
      this.imageDescription = item.description
      this.$bvModal.show(`image-description-modal-${item.id}`)
    },
    async updateImageDescription(item) {
      await suppliers.images(this.$route.params.id).update(item.id, { description: this.imageDescription })
      this.$bvModal.hide(`image-description-modal-${item.id}`)
      this.$bvToast.toast(`Description changed to "${this.imageDescription}"`, {
        title: 'Image description updated',
        variant: 'primary',
      })
      this.loadContent()
    },
    handleNewImage(close) {
      this.imageError = false
      this.loadImageTab()
      close()
    },
    handleNewContent(close) {
      this.$bvToast.toast(`Content added for supplier ${this.supplier.booking_name}`, {
        title: 'New Content',
        variant: 'primary',
      })
      this.loadContentTab()
      close()
    },
    handleEditContent(close) {
      this.$bvToast.toast(`Content edited for supplier ${this.supplier.booking_name}`, {
        title: 'Content Edited',
        variant: 'primary',
      })
      this.loadContentTab()
      close()
    },
    handleNewGallery(close) {
      this.$bvToast.toast(`Gallery added for supplier ${this.supplier.booking_name}`, {
        title: 'New Gallery',
        variant: 'primary',
      })
      this.loadGalleryTab()
      close()
    },
    handleEditGallery(close) {
      this.$bvToast.toast(`Gallery edited for supplier ${this.supplier.booking_name}`, {
        title: 'Gallery Edited',
        variant: 'primary',
      })
      this.loadGalleryTab()
      close()
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-new-image__text {
  max-width: 80%;
  &_error {
    color: #ff3b30;
  }
}
</style>
