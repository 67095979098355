<template>
  <b-row>
    <b-col v-for="(item, i) in images" :key="i" :cols="2" class="mb-4">
      <b-img
        :src="item.image_small"
        fluid
        :style="{ cursor: 'pointer' }"
        :class="classNames(item)"
        :alt="item.title"
        @click="selectImage(item)"
      />
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import { suppliers } from '@/api'

export default {
  name: 'SupplierGalleryImagePicker',
  props: { images: Array, gallery: Object },
  data() {
    return {
      selectedImages: this.gallery.images.map(img => img.id),
    }
  },
  methods: {
    isActive(item) {
      return this.selectedImages?.filter(img => img !== null).some(galleryItem => galleryItem === item.id)
    },
    classNames(item) {
      const active = this.isActive(item)

      return ['rounded', { 'border border-primary border-4': active }]
    },
    async selectImage(item) {
      const active = this.isActive(item)
      if (active) {
        this.selectedImages = this.selectedImages.filter(id => item.id !== id)
      } else {
        this.selectedImages.push(item.id)
      }

      suppliers.gallery(this.$route.params.id).update(this.gallery.id, {
        images: this.selectedImages,
      })

      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.border-4 {
  border-width: 4px !important;
}
</style>
