<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData">
      <validation-provider name="Title" v-slot="context" rules="required">
        <b-form-group label="Title">
          <b-form-input
            v-model="title"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            placeholder="e.g Custom Content"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="Content" v-slot="context" rules="required">
        <b-form-group label="Content">
          <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
            {{ context.errors[0] }}
          </small>

          <froala v-model="customContent" />
        </b-form-group>
      </validation-provider>

      <b-row align-h="end">
        <b-col cols="auto">
          <b-button variant="link" class="mr-2" @click="$emit('close')">
            Close
          </b-button>

          <b-button variant="primary" :disabled="invalid" type="submit">
            {{ content ? 'Update' : 'Create' }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import { suppliers } from '@/api'
import { getValidationState } from '../page-helpers'
import Froala from '@/components/Froala.vue'

export default {
  name: 'SupplierGalleryContentForm',
  components: { Froala },
  props: { content: Object },
  data() {
    return { title: '', customContent: '' }
  },
  beforeMount() {
    if (this.content) {
      this.title = this.content.title
      this.customContent = this.content.content
    }
  },
  methods: {
    getValidationState,
    async submitFormData() {
      const data = { title: this.title, content: this.customContent }

      if (!this.content) {
        await suppliers.customContent.create(this.$route.params.id, data)
      } else {
        await suppliers.customContent.update(this.content.id, data)
      }

      this.$emit('success')
    },
  },
}
</script>
