<template>
  <draggable v-model="images" class="row" @end="setNewOrder">
    <b-col v-for="(item, index) in images" :key="index" cols="3">
      <b-img :src="item.image_small" fluid />
    </b-col>
  </draggable>
</template>

<script>
import { suppliers } from '@/api'
import Draggable from 'vuedraggable'

export default {
  components: { Draggable },
  props: ['gallery'],
  data() {
    return {
      galleryData: this.gallery,
      images: this.gallery.images,
    }
  },
  watch: {
    gallery() {
      this.images = this.gallery.images
    },
  },
  methods: {
    async setNewOrder() {
      const newOrder = this.images.map(item => item.id)

      await suppliers.gallery(this.$route.params.id).update(this.gallery.id, {
        id: this.gallery.id,
        description: this.gallery.description,
        images: newOrder,
      })
    },
  },
}
</script>
